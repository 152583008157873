.otp {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    width: 100%;
    align-items: center;
   }
   .font-link {
font-family:  'Noto Sans', sans-serif;
   }

   .font-header {
   font-family:  'Noto Sans', sans-serif;
   }

   .image {
      max-width: 200px;
      height: auto;
   }
   