.otp {
     display: flex;
     justify-content: center;
     margin-top: 20px;
     width: 100%;
     align-items: center;
    }
    #videobackground .gradient {
        background: -moz-linear-gradient(45deg, rgba(213, 15, 61, 0.6), rgba(13, 17, 198, 0.69) 100%);
        background: -webkit-linear-gradient(45deg, rgba(213, 15, 61, 0.6), rgba(13, 17, 198, 0.69) 100%);
        background: linear-gradient(45deg, rgba(213, 15, 61, 0.6), rgba(13, 17, 198, 0.69) 100%);
      }
      
      #videobackground .view video {
        top: 0!important;
        left: 0!important;
        transform: none!important;
        height: 100vh;
      }
.shadow-custom {
        box-shadow: 0 2px 20px 0 rgba(0, 0, 0, .25), 0 3px 10px 5px rgba(0, 0, 0, 0.05) !important;
      }
    .verifyDiv {
     padding: 18px;
    }
    p {
     color: black;
     margin-bottom: 0px;
    }

    .footer-email-link {
     margin-left: 124px;
 }
    .inputStyle {
     width: 50% !important;
     height: 45px;
     border-radius: 7px;
     border: 0px;
     margin-left: 8px;
     margin-right: 8px;
    background: #dddddd;
     font-size: 20px;
    }
    .p3 {
     font-size: 14px;
    }
    
    .otpElements {
     display: flex;
    flex-direction: column;
    align-items: baseline;
    margin-top: 20px;
     margin-bottom: 50px;
     padding-left: 18px;
    justify-content: center;
    }.p1 {
     font-family: "Poppins", sans-serif;
     font-weight: 800;
     margin-top: 25px;
     font-size: 27px;
    }.p2 {
     font-family: "Poppins", sans-serif;
    font-size: 13px;
    }
  
    .font-link {
      font-family: 'Roboto', sans-serif;
         }